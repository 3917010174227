<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 筛选部分 -->
      <div class="screen">
        <div style="margin-left: -88%; margin-bottom: 10px">
          促销>拼团活动列表
        </div>
        <div class="screen_cont">
          <el-form
            size="small"
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="margin-left: 8.5%; border-radius: 10px"
          >
            <el-form-item label="商品名称：">
              <el-input v-model="activeTitle" placeholder="商品名称"></el-input>
            </el-form-item>
            <el-form-item label="拼团状态：">
              <!-- @change="switchChange(scope.row.is_online, scope.row.active_id) -->
              <el-select
                v-model="active_status"
                placeholder="拼团状态"
                @change="changeStatus(active_status)"
              >
                <el-option label="全部" value="4"></el-option>
                <el-option label="未开始" value="1"></el-option>
                <el-option label="进行中" value="2"></el-option>
                <el-option label="已结束" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择商户：" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="merchantChange(business_id)"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
              <el-button
                @click="screen()"
                size="mini"
                type="danger"
                style="margin-left: 100px"
                >查询结果</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
        <div class="bck">
          <div class="table_head" style="margin-left: 2.5%">
            <div class="table_head_right">
              <ul>
                <li>
                  <el-button
                    size="mini"
                    type="danger"
                    @click="show = !show"
                    v-if="storeShow"
                  >
                    添加拼团商品
                  </el-button>
                </li>
              </ul>
              <div class="popContainer" v-show="show">
                <transition name="el-fade-in">
                  <div class="transition-box">
                    <el-form
                      ref="form"
                      :model="sizeForm"
                      label-width="80px"
                      size="mini"
                    >
                      <div class="act_tit" style="mar">添加拼团商品</div>
                      <el-form-item
                        v-show="merchantShow"
                        label="选择商户："
                        label-width="120px"
                      >
                        <el-select
                          v-model="business_id"
                          placeholder="请选择商户"
                          @change="currStationChange"
                        >
                          <el-option
                            v-for="item in businessList"
                            :key="item.business_id"
                            :label="item.business_name"
                            :value="item.business_id"
                          >
                          </el-option>
                        </el-select>
                        <!-- 选择门店 -->
                        <el-select
                          v-model="storeid"
                          placeholder="请选择门店"
                          @change="currChange(storeid)"
                        >
                          <el-option
                            v-for="item in storeList"
                            :key="item.storeid"
                            :label="item.s_name"
                            :value="item.storeid"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="商品名称：" label-width="120px">
                        <el-select v-model="goods_id" placeholder="请选择商品">
                          <el-option
                            v-for="item in goodsTuan"
                            :key="item.goods_id"
                            :label="item.gname"
                            :value="item.goods_id"
                            @click.native="
                              btnSel(
                                item.goods_id,
                                item.original_price,
                                item.gname
                              )
                            "
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="商品原价：" label-width="120px">
                        <el-input
                          v-model="sizeForm.original_price"
                          disabled
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="拼团商品价格：" label-width="120px">
                        <el-input v-model="sizeForm.group_price"></el-input>
                      </el-form-item>
                      <el-form-item label="团的数量：" label-width="120px">
                        <el-input v-model="sizeForm.group_number"></el-input>
                      </el-form-item>
                      <el-form-item label="每个团的人数：" label-width="120px">
                        <el-input
                          v-model="sizeForm.group_person_number"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="开始时间：" label-width="120px">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="sizeForm.date1"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                          <el-time-picker
                            placeholder="选择时间"
                            v-model="sizeForm.date2"
                            style="width: 100%"
                            value-format="HH:mm:ss"
                          ></el-time-picker>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="结束时间：" label-width="120px">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="sizeForm.endDate1"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                          <el-time-picker
                            placeholder="选择时间"
                            v-model="sizeForm.endDate2"
                            style="width: 100%"
                            value-format="HH:mm:ss"
                          ></el-time-picker>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="上架/下架：" label-width="120px">
                        <el-radio-group
                          v-model="sizeForm.resource"
                          size="medium"
                        >
                          <el-radio
                            border
                            label="上架"
                            name="123"
                            value="0"
                          ></el-radio>
                          <el-radio border label="下架" value="1"></el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item size="large">
                        <el-button type="danger" @click="onSubmit"
                          >立即创建</el-button
                        >
                        <el-button @click="show = false">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </transition>
              </div>
              <div class="popContainer" v-show="changeShow">
                <transition name="el-fade-in">
                  <div class="transition-box">
                    <el-form
                      ref="form"
                      :model="sizeForm2"
                      label-width="80px"
                      size="mini"
                    >
                      <div class="act_tit">添加拼团商品</div>
                      <el-form-item
                        v-show="merchantShow"
                        label="选择商户："
                        label-width="120px"
                      >
                        <el-select
                          v-model="business_id"
                          placeholder="请选择商户"
                          @change="currStationChange"
                        >
                          <el-option
                            v-for="item in businessList"
                            :key="item.business_id"
                            :label="item.business_name"
                            :value="item.business_id"
                          >
                          </el-option>
                        </el-select>
                        <!-- 选择门店 -->
                        <el-select v-model="storeid" placeholder="请选择门店">
                          <el-option
                            v-for="item in storeList"
                            :key="item.storeid"
                            :label="item.s_name"
                            :value="item.storeid"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="选择商品：" label-width="120px">
                        <el-select v-model="storeid" placeholder="请选择门店">
                          <el-option
                            v-for="item in storeList"
                            :key="item.storeid"
                            :label="item.s_name"
                            :value="item.storeid"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="商品原价：" label-width="120px">
                        <el-input v-model="sizeForm2.active_title"></el-input>
                      </el-form-item>
                      <el-form-item label="拼团商品价格：" label-width="120px">
                        <el-input v-model="sizeForm2.active_title"></el-input>
                      </el-form-item>
                      <el-form-item label="团的数量：" label-width="120px">
                        <el-input v-model="sizeForm2.active_title"></el-input>
                      </el-form-item>
                      <el-form-item label="每个团的人数：" label-width="120px">
                        <el-input v-model="sizeForm2.active_title"></el-input>
                      </el-form-item>

                      <el-form-item label="开始时间：" label-width="120px">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="sizeForm2.date1"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                          <el-time-picker
                            placeholder="选择时间"
                            v-model="sizeForm2.date2"
                            style="width: 100%"
                            value-format="HH:mm:ss"
                          ></el-time-picker>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="结束时间：" label-width="120px">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="sizeForm2.endDate1"
                            style="width: 100%"
                            value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                          <el-time-picker
                            placeholder="选择时间"
                            v-model="sizeForm2.endDate2"
                            style="width: 100%"
                            value-format="HH:mm:ss"
                          ></el-time-picker>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="上架/下架：" label-width="120px">
                        <el-radio-group
                          v-model="sizeForm2.resource"
                          size="medium"
                        >
                          <el-radio border label="0">上架</el-radio>
                          <el-radio border label="1">下架</el-radio>
                        </el-radio-group>

                        <!-- <template slot-scope="scope">
			                <el-switch
			                  @change="
			                    switchChange(scope.row.is_online, scope.row.id)
			                  "
			                  v-model="scope.row.is_online"
			                  active-color="#13ce66"
			                  inactive-color="#ff4949"
			                  :active-value="1"
			                  :inactive-value="2"
			                >
			                </el-switch>
			              </template> -->
                      </el-form-item>

                      <el-form-item size="large">
                        <el-button type="primary" @click="onChange"
                          >修改</el-button
                        >
                        <el-button @click="changeShow = false">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <el-table
            :data="tableData"
            border
            style="width: 95%; margin-left: 2.5%; text-align: center"
          >
            <el-table-column prop="goods_name" label="商品名称" align="center">
            </el-table-column>
            <el-table-column
              prop="business_name"
              label="所属商户"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="store_name" label="所属门店" align="center">
            </el-table-column>
            <el-table-column prop="" label="活动状态" align="center">
              <template slot-scope="scope">
                <span>{{
                  scope.row.status == 1
                    ? "未开始"
                    : scope.row.status == 2
                    ? "进行中"
                    : scope.row.status == 3
                    ? "已结束"
                    : "全部"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="group_price" label="拼团价" align="center">
            </el-table-column>

            <el-table-column
              prop="group_person_number"
              label="成团人数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="group_number"
              label="拼团数量"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="start_time" label="开始时间" align="center">
            </el-table-column>
            <el-table-column prop="end_time" label="结束时间" align="center">
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <!--         <span
                style="cursor: pointer"
                @click="setGoods(scope.row.active_id, scope.row.business_id)"
                >设置商品
              </span> -->
                <!--          <span
                style="margin-left: 10px; cursor: pointer"
                v-show="storeShow"
                @click="
                  edit(
                    scope.row.active_id,
                    scope.row.active_title,
                    scope.row.start_time,
                    scope.row.end_time,
                    scope.row.is_online,
                    scope.row.business_id
                  )
                "
              >
                编辑
              </span> -->
                <span
                  v-show="storeShow"
                  style="margin-left: 10px; cursor: pointer"
                  @click="delActive(scope.row.group_active_id)"
                >
                  删除</span
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="activeListTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 分页 -->
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";

export default {
  data() {
    return {
      top1: "7-4",
      top2: ["7"],
      flag: 1,
      admin_type: "",
      //下拉框内容
      formInline: {
        user: "",
        region: "",
        num: "",
        order: "",
      },
      activeTitle: "", //活动名称搜索的input框的值
      active_status: "", //选择活动状态
      merchant_status: "", //选择商户
      merchantShow: false, //商户个别权限的隐藏
      storeShow: false, //门店权限的隐藏
      show: false,
      changeShow: false,
      businessList: [{}],
      business_id: "",
      goods_id: "",
      storeList: [{}],
      storeid: "",
      //添加活动
      goodsTuan: [],

      sizeForm: {
        original_price: "",
        group_active_id: "",
        goods_id: "",
        storeid: "",
        gname: "",
        group_number: "",
        group_person_number: "",
        group_price: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        resource: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",

        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        type: [],
        resource: "",
      },
      cur_page: 0, //设置一个默认值
      tableData: [], //表格渲染数据
      activeListTotal: 10, //表格总条数
      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      value2: true,
    };
  },
  methods: {
    //活动名称查询按钮
    btnSel(goods_id, original_price, gname) {
      console.log(goods_id, original_price);
      this.sizeForm.original_price = original_price;
      this.sizeForm.gname = gname;
      this.sizeForm.goods_id = goods_id;
    },

    tuan(e) {
      console.log(e);
    },
    screen() {
      console.log(this.activeTitle);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: 4, //活动状态
          type: 2, //1是秒杀，2是团购
          active_title: this.activeTitle,
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
    },
    //选择门店切换列表
    merchantChange(business_id) {
      console.log(business_id);
      this.$request
        .groupActiveList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          login_type: this.$storage.getLocal("type"), //身份
          status: 4,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          // this.activeListTotal = res.data.count;
          // this.activeListTotal = res.data.count;
        });
    },
    //修改上下架
    switchChange(is_online, active_id) {
      console.log(is_online, active_id);
      this.$request
        .changShelf({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          active_title: this.sizeForm.name,
          start_time: this.sizeForm.date1 + " " + this.sizeForm.date2,
          end_time: this.sizeForm.endDate1 + " " + this.sizeForm.endDate2,
          is_online: is_online,
          type: 1, //1是秒杀，2是团购
          active_id: active_id,
          business_id: this.business_id,
        })
        .then((res) => {
          console.log(res);
        });
    },
    //刷新页面按钮
    refresh() {
      location.reload();
    },
    // 监听选择商户变化
    currStationChange() {
      this.$request
        .businessStoreList({
          business_id: this.business_id,
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
        })
        .then((res) => {
          this.storeList = res.data;
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = parseInt(`${val}`);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: 4, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.activeListTotal = res.data.count;
          }
        });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: 4, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: this.currentpage,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.activeListTotal = res.data.count;
            console.log(this.tableData);
          }
        });
    },
    onSubmit() {
      if (
        // this.sizeForm.name == "" ||
        this.sizeForm.resource == "" ||
        this.sizeForm.date1 == "" ||
        this.sizeForm.date2 == "" ||
        this.sizeForm.endDate1 == "" ||
        this.sizeForm.endDate2 == ""
      ) {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
        console.log("submit!");
        console.log(this.sizeForm);
        // return false
        console.log(this.business_id);
        if (this.sizeForm.resource == "上架") {
          this.sizeForm.resource = 0;
        }
        if (this.sizeForm.resource == "下架") {
          this.sizeForm.resource = 1;
        }
        console.log(this.sizeForm.name);
        this.$request
          .setGroupActive({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            login_type: this.$storage.getLocal("type"), //身份
            group_active_id: this.sizeForm.group_active_id,
            store_id: this.sizeForm.storeid,
            goods_id: this.sizeForm.goods_id,
            goods_name: this.sizeForm.gname,
            group_number: this.sizeForm.group_number,
            group_person_number: this.sizeForm.group_person_number,
            group_price: this.sizeForm.group_price,
            original_price: this.sizeForm.original_price,
            start_time: this.sizeForm.date1 + " " + this.sizeForm.date2,
            end_time: this.sizeForm.endDate1 + " " + this.sizeForm.endDate2,
            is_on_sale: this.sizeForm.resource,
          })
          .then((res) => {
            if (res.code == 0) {
              // (this.sizeForm.date1 = ""),
              // (this.sizeForm.date2 = ""),
              // (this.sizeForm.endDate1 = ""),
              // (this.sizeForm.endDate2 = ""),
              // (this.sizeForm.type = ""),
              // (this.sizeForm.resource = ""),
              this.show = false;
            } else {
              alert(res.data.msg);
            }
          });
      }
    },
    //设置商品
    setGoods(active_id, business_id) {
      console.log("businsee_id", business_id);
      this.$router.push({
        name: "setGoods",
        query: { active_id: active_id, business_id: business_id },
      });
    },
    //编辑商品
    edit(
      active_id,
      active_title,
      start_time,
      end_time,
      is_online,
      business_id
    ) {
      console.log(
        "编辑商品的默认值",
        this.sizeForm2.id,
        active_id,
        active_title,
        start_time,
        end_time,
        is_online,
        business_id
      );
      console.log(is_online);
      console.log(typeof is_online);
      this.changeShow = true;
      this.business_id = business_id;
      this.sizeForm2.active_id = active_id;
      this.sizeForm2.date1 = start_time;
      this.sizeForm2.date2 = start_time;
      this.sizeForm2.endDate1 = end_time;
      this.sizeForm2.endDate2 = end_time;
      // this.sizeForm2.resource = end_time;
      this.sizeForm2.active_title = active_title;
      this.sizeForm2.resource = is_online.toString();
      // this.sizeForm2.resource = '1';
      console.log(this.sizeForm2.resource);
      /*   this.$request
      .getUpStoreData({
        token: this.$storage.getLocal("token"),
        storeid: edit.store_id,
      })
      .then((r) => {
        console.log('商户id', r);
        if (r.code == 0) {
          //   console.log(r);
          this.ruleForm.business_id = r.data.pstoreid;
          this.$request
            .getStoreDataList({
              token: this.$storage.getLocal("token"),
              pstoreid: r.data.pstoreid,
              login_type: this.$storage.getLocal("type"),
            })
            .then((r) => {
              console.log(1233333333333, r);
              if (r.code == 0) {
                this.ruleForm.storeList = r.data;
                this.ruleForm.storeid = parseInt(edit.store_id);

                console.log(r);
              }
            });
        }
      }); */
    },
    //编辑活动的提交按钮
    onChange() {
      if (
        this.sizeForm2.title == "" /* ||
        this.sizeForm.resource == "" ||
        this.sizeForm.date1 == "" ||
        this.sizeForm.date2 == "" ||
        this.sizeForm.endDate1 == "" ||
        this.sizeForm.endDate2 == "" */
      ) {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
        if (this.sizeForm.resource == "上架") {
          this.sizeForm.resource = 1;
        }
        if (this.sizeForm.resource == "下架") {
          this.sizeForm.resource = 2;
        }
        console.log(this.sizeForm.name);
        this.$request
          .addActive({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份
            active_title: this.sizeForm2.active_title,
            start_time: this.sizeForm.date1 + " " + this.sizeForm.date2,
            end_time: this.sizeForm.endDate1 + " " + this.sizeForm.endDate2,
            is_online: this.sizeForm.resource,
            type: 1, //1是秒杀，2是团购
            active_id: this.sizeForm2.active_id,
            business_id: this.business_id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$request
                .activeList({
                  token: this.$storage.getLocal("token"), //登陆进来的tooken
                  admin_type: this.$storage.getLocal("type"), //身份
                  status: 4, //活动状态
                  type: 1, //1是秒杀，2是团购
                  active_title: "",
                  business_id: "",
                  page: 1,
                  limit: this.pageSize,
                })
                .then((res) => {
                  this.tableData = res.data.data;
                  this.activeListTotal = res.data.count;
                });
            } else {
              alert(res.msg);
            }
          });
      }
    },
    currChange(storeid) {
      console.log(storeid);
      this.sizeForm.storeid = storeid;
      this.$request
        .groupGoodsList({
          store_id: storeid,
        })
        .then((res) => {
          console.log(res);
          this.goodsTuan = res.data;
        });
    },
    //删除商品
    delActive(group_active_id) {
      console.log(group_active_id);
      this.$request
        .deleteGroupActive({
          token: this.$storage.getLocal("token"),
          group_active_id: group_active_id,
        })
        .then((res) => {
          console.log(res);
          this.$request
            .groupActiveList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              login_type: this.$storage.getLocal("type"), //身份
              status: 4, //活动状态
              type: 2, //1是秒杀，2是团购
              active_title: "",
              business_id: "",
              page: 1,
              limit: this.pageSize,
            })
            .then((res) => {
              this.tableData = res.data.data;
              this.activeListTotal = res.data.count;
            });
        });
    },
    // 选中拼团状态
    changeStatus(merchant_status) {
      console.log(merchant_status);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: this.active_status, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
          // }
        });
    },
  },

  created() {
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
    });
    this.admin_type = this.$storage.getLocal("type");
    console.log("business_id", this.business_id);
    // let business_id = this.business_id;
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
      this.storeShow = true;
    }

    this.$request
      .groupActiveList({
        token: this.$storage.getLocal("token"), //登陆进来的tooken
        login_type: this.$storage.getLocal("type"), //身份
        status: 4,
      })
      .then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        // this.activeListTotal = res.data.count;
        // this.activeListTotal = res.data.count;
      });
  },
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  /* border: 1px solid #ccc; */
  /* margin-top: 15px; */
  /* width: 85%; */
  margin-left: 200px;
  height: 90px;
  background-color: rgb(245, 247, 249);
  padding-top: 10px;
  padding-bottom: 25px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  /*  margin-left: 20px;
  padding-top: 10px; */
  display: flex;
  /* font-size: 18px; */
  width: 97.5%;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  margin-left: 1.25%;
  /* margin-top: 20px; */
  padding-right: 11%;
  padding-top: 10px;
  padding-bottom: 20px;
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.transition-box {
  position: absolute;
  z-index: 2;
  top: 80px;
  left: 27%;
  width: 800px;
  height: 600px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  color: #000000;
  background-color: #f5f7fa;
  margin-top: -40px;
  margin-left: -20px;
  padding-left: 10px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
.table {
  background-color: rgb(245, 247, 249);
  padding-top: 10px;
  padding-bottom: 25px;
  min-height: 565px;
}

.bck {
  width: 85%;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  margin-left: 14%;
  /* margin-top: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* line-height: 20px; */
}
</style>